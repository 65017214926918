import React from "react"
import MyNavLink from './my-nav-link'
import './layout-b1.css'
import { navigate } from "@reach/router"
import PubSub from "pubsub-js"

class LayoutB1 extends React.Component {

  render() {
    const orgs=[{type: '主办单位', desc: '中国田径协会 江苏省体育局 簇格技术产业开发区管理委员会'}, {type: '承办单位', desc: '苏州体育局 苏州簇格工委宣传部 苏州簇格文化体育和旅游局'}, {type: '冠名单位', desc: '苏州簇格技术产业股份有限公司'}, {type: '运营支持单位', desc: '苏州市簇格软件有限公司'}, {type: '运营单位', desc: '江苏省体育竞赛有限公司'} ]
    const raceorg={name: '苏州簇格马拉松组委会', address: '苏州工业园区星桂街', phone: '0512-00000000', wx: '————'}
    const recordNo='苏ICP备——号 版权所有2018苏州簇格马拉松 ALL RIGHTS RESEREVD'

    const { location, title, children } = this.props
    return (
      <div >
        <Header/>
        <main className="b1-main-bg">{children}</main>
        <Footer  raceorg={raceorg} orgs={orgs} recordNo={recordNo}/>
      </div>
    )
  }
}

function Footer (props){
  const orslist=props.orgs.map((org,index)=><div key={index} className="org"><span className="org-type">{org.type}</span>{org.desc}</div>)
  const  color="black"
  return (
    <div className="footer" style={{background:color}}>
      <div className="supporters">
        <div className="orgs">
          {orslist}
        </div>
        <div className="race-org">
          <div className="name">名称: {props.raceorg.name}</div>
          <div className="address">地址:{props.raceorg.address}</div>
          <div className="phone">客服电话:{props.raceorg.phone} </div>
          <div className="wx">官网微信: {props.raceorg.wx}</div>
        </div>
      </div>
      <div className="line"></div>
      <div className="bottom">
        <div className="record-no">{props.recordNo}</div>
        <div>簇格软件 提供技术支持</div>
      </div>
    </div>
  )
}
class Header extends React.Component {
  state={
    loged:false,
    showmenu:false
  }
  componentDidMount() {
    PubSub.subscribe('login',(msg,data)=>{
      this.login()
    })
  }
  login=()=>{
    console.log("loged:",this.state.loged)
    this.setState({
      loged:true
    })

  }
  clicklogin=()=>{
    navigate('/demos/themeB1/profile/login')
  }
  logout=()=>{
    this.setState({
      loged:false
    })
  }
  showMenu=()=>{
    this.setState({
      showmenu:!this.state.showmenu
    })
  }
  clicknav=()=>{
    this.setState({
      showmenu:!this.state.showmenu
    })
  }
  render() {
    const navs=[
      {name:'首页',path:'/demos/themeB1/home'},
      {name:'赛事',path:'/demos/themeB1/races'},
      {name:'资讯',path:'/demos/themeB1/notices'},
      {name:'文章',path:'/demos/themeB1/articles'},
      {name:'关于我们',path:'/demos/themeB1/about_us'}]
    const loged=this.state.loged
    const navlist=navs.map((nav,index)=><MyNavLink key={index} to={nav.path}><div className="menu">{nav.name}</div></MyNavLink>)
    const navlistPhone=navs.map((nav,index)=><div className="menu-phone" key={index}>
      <MyNavLink key={index} onClick={this.clicknav}  to={nav.path}><div className="menu-phone-content">{nav.name}</div></MyNavLink>
    </div>)

    return(
      <div className="b1-top">
        <img alt="logo" className="b1-logo" src={require('../images/b1/logo.png')}/>
        <div className="menus-pc" >
          <div className="top-menus">
            {/*导航路由链接*/}
            {navlist}
          </div>
          <MyNavLink   to='/demos/themeB1/search' className="search-icon">
            <img src={require('../images/b1/search.svg')} alt="搜索"/>
          </MyNavLink>
          {!loged&&<div onClick={this.clicklogin}>登录</div>}
          {loged&& <div style={{display:'flex',alignItems: 'flex-end'}}>
            <MyNavLink to='/demos/themeB1/profile/enroll_list'>个人中心</MyNavLink >
            <div className="logout" onClick={this.logout}>退出</div>
          </div>}
        </div>

        <div className="menu-icon">
          <img src={require('../images/b1/menu.png')} alt="menu" onClick={this.showMenu}/>
        </div>
        <div className="menus-phone" style={{display:this.state.showmenu?'block':'none'}}>
          <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginBottom:'20px'}}>
            <img src={require('../images/b1/avatar.png')} className="phone-avatar" alt="avatar"/>
            {!loged&&<div onClick={this.clicklogin}>登录</div>}
            {loged&& <div style={{display:'flex',alignItems: 'flex-end'}}>
              <MyNavLink to='/demos/themeB1/profile/enroll_list'>个人中心</MyNavLink >
              <div className="logout" onClick={this.logout}>退出</div>
            </div>}
          </div>
          <div className="menu-phone">
            <MyNavLink  onClick={this.clicknav}  to='/demos/themeB1/search'><div className="menu-phone-content">搜索</div></MyNavLink>
          </div>
          {navlistPhone}
        </div>
      </div>
    )
  }
}

export default LayoutB1
