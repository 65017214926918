import React from "react";
import './fast-entry.css'
import MyNavLink from './my-nav-link'

export default class FastEntry extends React.Component{
    // pushRoute=(e)=>{
    //     console.log("e:",e)
    //     this.props.history.push('/'+e)
    // }
    render() {
        const entry1='search_enroll'
        const entry2='search_grades'
        return(
            <div className="fast-entry-container">
                <div className="fast-entry">
                    <MyNavLink to={'/demos/themeB1/'+entry1}>
                        <div className="fast-entry1">
                            <img alt="search" src={require('../images/b1/enroll.svg')}/>
                            <div>报名查询</div>
                        </div>
                    </MyNavLink>
                </div>
                <div className="fast-entry">
                    <MyNavLink to={'/demos/themeB1/'+entry2}>
                        <div className="fast-entry2">
                            <img alt="search" src={require('../images/b1/query_results.svg')}/>
                            <div>查看成绩</div>
                        </div>
                    </MyNavLink>

                </div>

                <div className="adv">广告位</div>
            </div>
        )
    }
}