import React from "react"
import LayoutB1 from "../../components/layout-b1"
import FastEntry from '../../components/fast-entry'
import './articles.css'
import { navigate } from "@reach/router"

export default class Articles extends React.Component {
  toDetail=()=>{
    navigate('/demos/themeB1/notice_detail')
  }
  render() {
    const articles=[{title:'泰马虐我千百遍，我待泰马如初恋',img:'//stor.ihuipao.com/image/c15640fb80fdfd701d8e39fd090e980c.jpg?imageView2/2/w/1600/q/90',desc:'五年前，一个电话，一句相，一份深深的同窗情谊，平时连5公里都没有跑过的我就匆匆踏上泰马的“魔鬼”赛道。激情与兴奋相伴，痛苦与煎熬并存。想着自己夸下的海口，想着给儿子许下的奖牌承诺，看看漫漫长坡，即使痛苦，依然坚持着，奔跑着。因为我相信自己一定能完成，也必须完成...'},
      {title:'想约宜马情醉三峡一—宜马小记',img:'//www.yichangmarathon.com/upload/site/1105/img/indexnewsfocus/201811/05/a520d686-56ad-40e0-a9ec-43f03498cece.png?_t_=1541453732',desc:'夷陵放郡，宜昌新府。水至此而夷，山至此而陵，宜山宜水，始有其昌。西控巴蜀，东接荆襄，千年要冲，地僻且荒。永叔为更，施善政德法于斯，风俗一载而淳，万民敬仰!'}]
    const articleslist=articles.map((article,index)=><div key={index} className="article" onClick={this.toDetail}>
      <div className="article-left-container">
        <div className="article-title">{article.title}</div>
        <div className="article-desc">{article.desc}</div>
      </div>
      <div className="article-right">
        <div className="article-img" style={{backgroundImage:'url('+article.img+')'}}>
        </div>
      </div>

    </div>)
    const articleslistPhone=articles.map((article,index)=><div key={index} className="article-phone">
      <div className="article-title">{article.title}</div>
      <div className="article-desc">{article.desc}</div>
      <div className="article-img-container">
        <img src={article.img} alt="img" />
      </div>
    </div>)
    return(
      <LayoutB1 location={this.props.location} >
        <div className="all-articles">
          <FastEntry />
          <div className="articles-list">
            {articleslist}
          </div>
          <div className="articles-list-phone">
            {articleslistPhone}
          </div>
        </div>
      </LayoutB1>
    )
  }
}

